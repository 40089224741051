import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
import { Testimonials } from "./components/testimonials";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

// Initialize Firebase

const firebaseConfig = {
  apiKey: "AIzaSyAkr0qowgWhQDQ31W-tn7gFAGjJVT30frU",
  authDomain: "domotekmaroc.firebaseapp.com",
  projectId: "domotekmaroc",
  storageBucket: "domotekmaroc.appspot.com",
  messagingSenderId: "1074070473901",
  appId: "1:1074070473901:web:45a35962cecd6c51bbca24",
  measurementId: "G-LVRNXFSTM5",
};

const app = initializeApp(firebaseConfig);
// Initialize Analytics and get a reference to the service
const analytics = getAnalytics(app);

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
    logEvent(analytics, "screen_view", {
      firebase_screen: "landingpage",
      firebase_screen_class: "landingpage",
    });
  }, []);

  return (
    <div>
      <Navigation />
      <Header data={landingPageData.Header} />
      <Features data={landingPageData.Features} />
      <About data={landingPageData.About} />
      {/*<Services data={landingPageData.Services} />
      <Gallery data={landingPageData.Gallery} />
      <Testimonials data={landingPageData.Testimonials} />
      <Team data={landingPageData.Team} />
      <Contact data={landingPageData.Contact} />*/}
    </div>
  );
};

export default App;
